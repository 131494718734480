//$tintColor: #2c637c;
//$tintColor: #312661;
$tintColor: #dea749;
//$tintColor: #f6bc26;

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: #555;
  background-color: #fcf9f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 11pt;
  line-height: 18pt;
}

h1, h2, h3 {
  color: #111;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  //color: $tintColor;

}

h3 {
  font-size: 14pt;
}

h1:after {
  content: '';
  display: block;
  width: 90px;
  height: 1px;
  margin-left: -30px;
  margin-top: 8px;
  //position: absolute;
  border-bottom: 2px solid $tintColor;
}

h1 .light, h2 .light {
  font-weight: 200;
}

/*#logo {*/
/*    display: block;*/
/*    margin: 0 auto;*/
/*    height: 150px;*/
/*}*/

.container {
  padding: 50px;
}

.video {
  background-color: #000;
  /*padding: 50px;*/
}

.column.video {
  padding: 0 !important;;
}

.video-container {
  background-color: #0b0039;
  /*padding: 20px;*/
}

.video-container video {
  border: 1px solid #555;
}

.video-container video, .big-video-container video {
  width: 100%;
}

.big-video-container video {
  max-height: 600px;
}

.on-video-box {
  position: relative;
  z-index: 5;
  margin-left: -100px;
  padding: 10px 15px;
  /*background-color: #fff;*/
  background-color: #fcf9f7;
  border: 1px solid #555;
  margin-top: 20px;

  &.left {
    margin-left: 0;
    margin-right: -100px;
  }

  h1:after, h2:after, h3:after {
    display: none;
  }

}
@media only screen and (max-width: 768px) {
  .on-video-box {
    margin: 0;
  }
}


.spaced-section {
  margin-top: 80px;
  margin-bottom: 80px;
}

.padded-container {
  margin-left: 20px;
  margin-right: 20px;
}

ul.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

ul.menu li {
  margin: 0;
  padding: 0;
}

ul.menu a {
  display: block;
  color: #616161;
  font-weight: 200;
  font-size: 14pt;
  padding: 10px 0;
  margin: 0 14px;
  text-decoration: none;
  /*border-radius: 5px;*/
}

ul.menu li a.active {
  /*background-color: #eee;*/
  border-bottom: 1px double $tintColor;
  //text-decoration: underline;
}

ul.menu a:hover {
  color: $tintColor;
  border-bottom: 1px double $tintColor;
}

a {
  color: #000;
}

a:hover {
  text-decoration: underline;
  /*color: #000;*/
  color: #2baec6;
}

a.arrow img {
}

a.arrow:hover img {
  opacity: 1;
  margin-right: 0;
}

.video-container-with-arrow {
  position: relative;

  .arrow {
    position: absolute;
    bottom: 0px;
    right: 10px;
    width: 80px;
    opacity: 0.5;
    margin-right: 6px;
    transition: all 0.2s;
  }

  a:hover .arrow {
    opacity: 1;
    margin-right: 0;
  }



}

video {
  max-width: 100%;
}

.pull-right {
  float: right;
  text-align: right;
}

.header {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  //border-bottom: 2px solid $tintColor;
  //background-color: rgba(44, 99, 124, 0.5);

  .ui.container {
    padding-bottom: 0;
  }

}

.header-video {
  height: 100vh;
  min-height: 600px;
  text-align: center;
  position: relative;
  max-width: none;
  background-color: #000;

  .logo-on-header-video {
    position: absolute;
    top: 50px;
    left: 60px;
    height: 150px;
    //opacity: 0.6;

    img {
      height: 100%;
    }

  }

}

.header-video video {
  height: 100%;
  object-fit: cover;
}

.header {
  position: relative;
  height: 20%;
  /*height: 100px;*/
}

#logo {
  display: block;
  height: 100%;
  max-height: 140px;
  margin: 0 auto;
  margin-top: 10px;
}

#award-container {
  position: absolute;
  top: 60px;
  right: 0px;
  text-align: right;
  background-color: #fff;
  padding-top: 6px;

  #award {
    padding: 12px;
    background-color: #f6bc26;
  }

  img {
    width: 120px;
  }

  h3 {
    color: #000;
    margin: 0;
    font-size: 10pt;
  }
  h4 {
    margin: 0;
    font-weight: 100;
    font-size: 9pt;
  }

  &:hover {
    color: #000;
    text-decoration: none;

    #award {
      background-color: #f5d37d;
    }
  }

}


.contactPage {
  //text-align: center;

  .circle {
    $size: 200px;
    background-color: $tintColor;
    border-radius: $size / 2;
    height: $size;
    width: $size;
    padding-top: 10px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  img {
    margin: 0 auto;
    margin-top: 20%;
    //margin: 40px auto;
    height: 50%;
  }

}

.ui.form .field>label {
  //color: $tintColor;
}

.footer-container {

  &.with-image {
    //height: 600px;
    //height: 900px;
    position: relative;

    img {
      //position: absolute;
      //top: 0;
      //left: 0;
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

  }

}

.footer {
  background-color: #222;
  background-color: rgba(20, 20, 20, 0.8);
  color: $tintColor;
  //color: rgb(213, 225, 231);

  h1, h2, h3 {
    color: $tintColor;
    &:after {
      border-color: #fff;
    }
  }
  a {
    color: $tintColor;
  }
}

.ui.form .field input:focus {
  border-color: $tintColor;
  //font-weight: 100;
}


.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}
.icon-scroll {
  opacity: 0;
  width: 30px;
  height: 55px;
  margin-left: -20px;
  bottom: 20px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
  transition: opacity 1000ms;
}
.icon-scroll:before {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}




@media only screen and (max-width: 768px) {
  #award {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 15px;
  }

  h3 {
    color: #000;
    margin: 0;
    font-size: 100%;
  }
  h4 {
    margin: 0;
    font-weight: 100;
    font-size: 90%;
  }

  .contactPage .circle {
    $size: 120px;
    border-radius: $size / 2;
    height: $size;
    width: $size;
  }

}

.embed-container {
  --video--width: 640;
  --video--height: 360;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  background: black;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}